import { useStaticQuery, graphql } from "gatsby"

const setArticleType = (article, types) => {
  article.type = types.find((type) => type.strapiId === article.type)
}

export const useBlogQuery = () => {
  const queryResults = useStaticQuery(query)
  const articleTypes = queryResults.allStrapiArticleTypes.edges.map(
    (item) => item.node
  )
  const data = queryResults.allStrapiBlog.edges[0].node
  const content = JSON.parse(JSON.stringify(data))
  let { articleGroup, editorPicks, spotlight } = content

  articleGroup.forEach((item) => {
    item.articles.forEach((article) => {
      setArticleType(article, articleTypes)
    })
  })
  setArticleType(editorPicks.leftArticle, articleTypes)
  setArticleType(editorPicks.rightArticle, articleTypes)
  setArticleType(spotlight.leftArticle, articleTypes)
  setArticleType(spotlight.topRightArticle, articleTypes)
  setArticleType(spotlight.bottomRightArticle, articleTypes)

  return {
    articleGroup,
    editorPicks,
    spotlight,
  }
}

const query = graphql`
  query BlogHomeQuery {
    allStrapiBlog {
      edges {
        node {
          spotlight {
            subtitle
            title
            leftArticle {
              description
              handle
              title
              image {
                url
              }
              type
            }
            topRightArticle {
              handle
              title
              image {
                url
              }
              type
            }
            bottomRightArticle {
              handle
              title
              image {
                url
              }
              type
            }
          }
          editorPicks {
            title
            rightArticle {
              description
              handle
              title
              image {
                url
              }
              type
            }
            leftArticle {
              description
              handle
              title
              image {
                url
              }
              type
            }
          }
          articleGroup {
            linkText
            title
            articleType {
              handle
            }
            articles {
              description
              handle
              title
              image {
                url
              }
              type
            }
          }
        }
      }
    }
    allStrapiArticleTypes {
      edges {
        node {
          color
          handle
          title
          strapiId
          videoArticles
        }
      }
    }
  }
`
