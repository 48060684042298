import React, { FC } from "react"
import ArticleCard, { ArticleCardProps } from "../../elements/ArticleCard"

export interface ArticlePicksProps {
  articles: ArticleCardProps[]
}

const ArticlePicks: FC<ArticlePicksProps> = ({ articles }) => {
  return (
    <div className="my-16">
      <div>
        <h1 className="m-0 my-4 text-3xl font-bold">Editors' Picks</h1>
      </div>
      <div className="grid sm:grid-cols-2 gap-4 sm:gap-8">
        {articles.map((item) => (
          <div key={item.title} className="col-span-1 row-span-1">
            <ArticleCard {...item} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ArticlePicks
