import * as React from "react"
import loadable from "@loadable/component"

// import { graphql } from "gatsby"
import { Layout } from "components/layouts/Layout/layout"
import ArticleGrid from "components/layouts/ArticleGrid"
import ArticlePicks from "components/layouts/ArticlePicks"
import BlogRow from "components/layouts/BlogRow"
import Container from "components/elements/Container"
import { useBlogQuery } from "hooks/useBlogQuery"
import { Seo } from "components/seo"

const Carousel = loadable(() => import("components/elements/Carousel"))

export default function Blog() {
  const { articleGroup, editorPicks, spotlight } = useBlogQuery()

  return (
    <Layout>
      <Seo title="Barista Blog" />
      <Container>
        <div className="my-6 lg:my-16 flex flex-row justify-center">
          <div className="w-full max-w-[76rem] mx-auto">
            <ArticleGrid
              primary={spotlight.leftArticle}
              secondary={[
                spotlight.topRightArticle,
                spotlight.bottomRightArticle,
              ]}
              title={spotlight.title}
              subtitle={spotlight.subtitle}
            />
            <ArticlePicks
              articles={[
                { ...editorPicks.leftArticle, smallText: true },
                { ...editorPicks.rightArticle, smallText: true },
              ]}
            />

            {articleGroup.map((item) => {
              item.articles = item.articles.map((article) => {
                return { ...article, boldText: true }
              })
              return (
                <div key={item.title}>
                  <BlogRow
                    title={item.title}
                    linkText={item.linkText}
                    posts={item.articles}
                    handle={`/blog/${item.articleType.handle}`}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </Container>

      <Carousel />
    </Layout>
  )
}
