import { Link } from "gatsby"
import React, { FC } from "react"
import { PlayIcon } from "@heroicons/react/solid"
import StrapiImage from "components/elements/StrapiImage"
import "react-lazy-load-image-component/src/effects/blur.css"

export interface ArticleType {
  color: string
  title: string
  handle: string
  strapiId: number
  videoArticles?: boolean
}
export interface ArticleCardProps {
  title: string
  subtitle: string
  description?: string
  image: { url: string }
  handle?: string
  smallText?: boolean
  type: ArticleType
}

const ArticleCard: FC<ArticleCardProps> = ({
  title,
  description,
  image,
  handle,
  smallText = false,
  type,
}) => {
  return (
    <Link to={`/blog/${handle}`}>
      <div className="max-w-[45.5rem] flex flex-col">
        <div className="relative">
          {type?.videoArticles && (
            <PlayIcon className="absolute bottom-[0.875rem] left-[0.875rem] h-10 w-10 text-white" />
          )}
          <StrapiImage
            image={image}
            className="w-full rounded-xl"
            loading="lazy"
          />
        </div>

        <div className="mt-6">
          <div
            className={`inline-block p-2 px-6 rounded-full`}
            style={{ backgroundColor: type.color }}
          >
            <p
              className="text-sm font-semibold uppercase"
              style={{ color: "#432B25" }}
            >
              {type.title}
            </p>
          </div>
          <div>
            <h3
              className={`m-0 mt-4 mb-2 font-bold text-idc-title leading-7 ${
                smallText ? "text-[1.75rem]" : "text-4xl"
              }`}
            >
              {title}
            </h3>
            <p className="text-[1.0625rem] leading-7 text-idc-body line-clamp-3">
              {description}
            </p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ArticleCard
