import React, { FC } from "react"
import ArticleCard, { ArticleCardProps } from "../../elements/ArticleCard"

export interface ArticleGridProps {
  primary: ArticleCardProps
  secondary: ArticleCardProps[]
  title: string
  subtitle: string
}

const ArticleGrid: FC<ArticleGridProps> = ({
  primary,
  secondary,
  title,
  subtitle,
}) => {
  return (
    <div className="mb-16">
      <div className="my-16 max-w-[50rem] mx-auto">
        <p className="text-center text-sm font-bold text-idc-brown mb-2 uppercase">
          {subtitle}
        </p>
        <h2 className="text-center text-idc-title font-extrabold text-5xl">
          {title}
        </h2>
      </div>

      <div className="flex flex-col md:flex-row gap-8">
        <ArticleCard {...primary} />

        <div className="max-w-[28.5rem] gap-y-8">
          {secondary.map((item) => {
            item.smallText = true
            return (
              <div key={item.title} className="mb-8 last:mb-0">
                <ArticleCard {...item} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ArticleGrid
